import React, { Component } from 'react';
// import { Mixpanel } from '../analytics/Mixpanel';

export class Logout extends Component {
  render() {
    return (
      <div>
        <h2>Thank you for visiting Music Meteorologist!</h2>
      </div>
    );
  }
}

export default Logout;
